import startCase from 'lodash/startCase';
import moment from 'moment';

import { formatNumber } from '../../../../utils/input';
import getFormattedPeriodDate from '../../../FormatPeriodDate/getFormattedPeriodDate';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.setAttribute('id', 'tooltip');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.padding = '10px';
    tooltipEl.style.zIndex = '9999';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

let timeoutHandle;

const externalTooltip = (context) => {
  if (context.remove === true) {
    const tooltipEl = context.chart.canvas.parentNode.querySelector('#tooltip');
    if (tooltipEl) {
      tooltipEl.remove();
    }
    return;
  }
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  while (tooltipEl.firstChild) {
    tooltipEl.firstChild.remove();
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const myTitle = document.createElement('p');
  myTitle.style.margin = '0';
  myTitle.style.padding = '0';
  const titleText =
    tooltip.ActualBloc.type === 'optimization'
      ? iff(
          tooltip.ActualBloc.display === false,
          tooltip.t('contract-detail-blocks-tooltip-price_not_displayed'),
          tooltip.t('contract-detail-blocks-tooltip-optimization'),
        )
      : tooltip.t('contract-detail-blocks-tooltip-fixation');
  myTitle.style.color = '#fff';
  myTitle.style.textAlign = 'left';
  myTitle.style.fontSize = '14px';
  myTitle.innerHTML = `<b style="color: #fff">${titleText.toUpperCase()}</b>`;
  tooltipEl.appendChild(myTitle);
  tooltipEl.style.width = '300px';
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  const tr = document.createElement('tr');
  tr.style.backgroundColor = 'inherit';
  tr.style.borderWidth = 0;
  tr.style.background = 'none';
  tr.style.display = 'flex';
  tr.style.flexDirection = 'column';
  const td1 = document.createElement('td');
  const td2 = document.createElement('td');
  const td3 = document.createElement('td');
  const td4 = document.createElement('td');
  const td5 = document.createElement('td');
  td1.style.borderWidth = 0;
  td2.style.borderWidth = 0;
  td3.style.borderWidth = 0;
  td4.style.borderWidth = 0;
  td5.style.borderWidth = 0;
  const text1 = document.createTextNode([
    `${tooltip.t('contract-detail-blocks-tooltip-date')} ${startCase(
      moment(tooltip.ActualBloc.date).format('DD/MM/YYYY').replace(moment(tooltip.ActualBloc.date).format('LT'), ''),
    )}`,
  ]);
  const text2 = document.createTextNode([
    `${tooltip.t('contract-detail-blocks-tooltip-period')} ${getFormattedPeriodDate({
      date: tooltip.ActualBloc.dateStart,
      periodMonths: tooltip.ActualBloc.months,
      format: 'MMM YYYY',
      isDesktop: true,
    })}`,
  ]);

  const amountValue = Math.round((tooltip.ActualBloc.amount + Number.EPSILON) * 100) / 100;
  const text3 = document.createTextNode([
    `${tooltip.t('contract-detail-blocks-tooltip-amount')} ${
      Math.round((amountValue / tooltip.minBlock) * 100) / 100
    } Clip (${formatNumber(Math.round((tooltip.ActualBloc.amount + Number.EPSILON) * 100) / 100)} 
    ${tooltip.t(`global-unit-${tooltip.fixationsUnit}`)})`,
  ]);
  const text4 = document.createTextNode([
    `${tooltip.t('contract-detail-blocks-tooltip-volume')} ${formatNumber(
      Math.round((tooltip.ActualBloc.consumption + Number.EPSILON) * 100) / 100,
    )} ${tooltip.t(`global-unit-${tooltip.consumptionsUnit}`)}`,
  ]);
  const text5 = document.createTextNode([
    `${tooltip.t('contract-detail-blocks-tooltip-price')} ${
      Math.round((tooltip.ActualBloc.price + Number.EPSILON) * 100) / 100
    } ${tooltip.t(`global-unit-price-${tooltip.consumptionsUnit}`)}`,
  ]);

  if (tooltip.ActualBloc.type === 'external') {
    td1.appendChild(text1);
    td2.appendChild(text2);
    td3.appendChild(text3);
    td4.appendChild(text4);
    td5.appendChild(text5);
    tr.appendChild(td1);
    tr.appendChild(td2);
    tr.appendChild(td3);
    tr.appendChild(td4);
    tr.appendChild(td5);
  } else if (tooltip.ActualBloc.type === 'optimization' && tooltip.ActualBloc.display === true) {
    td1.appendChild(text1);
    td2.appendChild(text2);
    td3.appendChild(text3);
    td4.appendChild(text4);
    tr.appendChild(td1);
    tr.appendChild(td2);
    tr.appendChild(td3);
    tr.appendChild(td4);
  }

  tooltipEl.appendChild(tr);

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.event.x}px`;
  tooltipEl.style.top = `${positionY + tooltip.event.y}px`;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.userSelect = 'none';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.padding = `10px`;
  tooltipEl.style.boxShadow = '0px 0px 5px 0px rgba(0,0,0,0.5)';
  tooltipEl.style.height = 'auto';
  tooltipEl.style.zIndex = '9999';

  // Reset timeout on previous timer
  if (timeoutHandle) clearTimeout(timeoutHandle);

  // Hide tooltip after delay
  timeoutHandle = setTimeout(() => {
    if (tooltipEl) {
      tooltipEl.style.opacity = 0;
    }
  }, 3000); // <-- time in milliseconds
};

export default externalTooltip;
