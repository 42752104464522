import startCase from 'lodash/startCase';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTRACT_FIXATION_GRANULARITY } from '../../constants';
import { useLocale } from '../../context/LocalContext';

const FormatPriodDateBloc = ({ granularity, date, periodMonths, format = 'MMMM YY', isCapitalize = false }) => {
  // Use to fix 1419 ticket
  const [t] = useTranslation();
  useLocale();

  let firstDate;

  if (granularity === CONTRACT_FIXATION_GRANULARITY.MONTH || (periodMonths && periodMonths === 1)) {
    firstDate = startCase(moment(date).locale('en').format(format));
  } else {
    firstDate = startCase(moment(date).locale('en').format(format));
  }

  const formattedStringDate = useMemo(() => {
    let stringDate;

    if (periodMonths && periodMonths === 1) stringDate = `${firstDate}`;
    if (periodMonths && periodMonths === 3) {
      stringDate = `Q${moment(firstDate).quarter()} ${moment(firstDate).format('YY')}`;
    }
    if (periodMonths && periodMonths === 6) {
      if (moment(firstDate).format('M') === '4') stringDate = `${t('format-summer')} ${moment(firstDate).format('YY')}`;
      else if (moment(firstDate).format('M') === '10')
        stringDate = `${t('format-winter')} ${moment(firstDate).format('YY')}`;
    }
    if (periodMonths && periodMonths === 12) {
      if (moment(firstDate).format('M') === '1') stringDate = `CAL ${moment(firstDate).add(11, 'month').format('YY')}`;
      if (moment(firstDate).format('M') === '10') stringDate = `GY ${moment(firstDate).format('YY')}`;
    }

    if (isCapitalize) {
      stringDate.toUpperCase();
    }

    return stringDate;
  }, [firstDate, isCapitalize, periodMonths, t]);

  return <div style={{ display: 'inline-block' }}>{formattedStringDate}</div>;
};

export default FormatPriodDateBloc;
